import axios from "axios";
import CONFIG from "../config/config";

export default class BaseApi {
  constructor(baseurl = CONFIG.apiUrl) {
    this.apiClient = axios.create({ baseURL: baseurl });
  }

  async request(options) {
    const response = await this.apiClient({ ...options });

    return response;
  }

  get(url, params = {}) {
    return this.request({ method: "get", url: `/${url}`, params });
  }

  post(url, data = {}, headers = {}) {
    return this.request({ method: "post", url: `/${url}`, data, headers });
  }

  put(url, data = {}) {
    return this.request({ method: "put", url: `/${url}`, data });
  }

  delete(url, params = {}) {
    return this.request({ method: "delete", url: `/${url}`, params });
  }
}

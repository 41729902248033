import { connect } from "react-redux";

import { deleteLicense } from "../store/actions/licenses";
import { checkSelectedItems } from "./selectors/gridSelectors";
import { isUserAdmin, getCurrentUser } from "./selectors/userSelectors";
import { ENVIRONMENTS } from "../constants/common";
import LicensesComponent from "../components/profile/Licenses/LicensesComponent";

const mapStateToProps = (state, { /*history, location: { state: locationState = {} }, */ filteredLicenses, isDev, backToDevelopment }) => {
  return {
    licenses: filteredLicenses,
    areSelectedItemsEmpty: checkSelectedItems(state, { name: ENVIRONMENTS.dev }),
    canEdit: isUserAdmin(state),
    isDev,
    backToDevelopment,
    company: state.session.company,
    user: getCurrentUser(state),
  };
};

const mapDispatchToProps = {
  deleteLicense,
};

const mergeProps = ({ isDev, company, ...stateProps }, { deleteLicense }) => ({
  ...stateProps,
  activePanel: isDev === true ? "dev" : "prod",
  isDev,
  platforms: company?.platform ? company.platform.map((type) => type.trim()) : [],
  deleteLicense,
  removeLicense: (license) => {
    // Waiting for the `app remove` API implementation.
    // deleteLicense(license, `${license.name} was successfully deleted.`);
    //
    // selectGridItem(license.production ? ENVIRONMENTS.prod : ENVIRONMENTS.dev, license.id, false, false);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LicensesComponent);

import { PERSONAL_SUBSCRIPTIONS, CARDIO_SUBSCRIPTIONS, STRENGTH_SUBSCRIPTIONS, ASSET_SUBSCRIPTIONS, FACILITY_SUBSCRIPTIONS } from "../components/profile/License/LicenseConstants";
import { EMAIL_REGEXP, PHONE_REGEXP, URL_REGEXP, IP_URL_REGEXP } from "./constants";

const _ = require("lodash");

const subscriptions = [...PERSONAL_SUBSCRIPTIONS, ...CARDIO_SUBSCRIPTIONS, ...STRENGTH_SUBSCRIPTIONS, ...ASSET_SUBSCRIPTIONS, ...FACILITY_SUBSCRIPTIONS];

let subscriptionRules = subscriptions
  .filter(({ name }) => name !== "facilitiesSubscribedFor")
  .map((subscription) => ({
    [subscription.name]: [
      {
        name: "Required",
        message: "URL Required. Please enter a valid URL.",
      },
      {
        name: "Invalid",
        func: (values) => {
          const currentValue = values[subscription.name];

          return currentValue && (currentValue.match(/^1/) || !(currentValue.match(URL_REGEXP) || currentValue.match(IP_URL_REGEXP)));
        },
        message: "Invalid URL. Please enter valid URL.",
      },
    ],
  }));

subscriptionRules = _.reduce(subscriptionRules, (prev, current) => _.assign(prev, current), {});
console.log("************ subscriptionRules: ", subscriptionRules);
export const COMMON_RULES = {
  email: [
    {
      name: "Required",
      message: "Please enter valid email.",
    },
    {
      name: "Invalid",
      func: (values) => !_.trim(values.email.toString()).match(EMAIL_REGEXP),
      message: "Please enter valid email.",
    },
  ],
  name: [
    {
      name: "Required",
      message: "Please enter name.",
    },
  ],
  firstName: [
    {
      name: "Required",
      func: ({ firstName }) => /^\s*$/.test(firstName),
      message: "Please enter name.",
    },
    {
      name: "Invalid",
      func: (values) => values.firstName.length < 2,
      message: "Please enter valid name.",
    },
  ],
  lastName: [
    {
      name: "Required",
      func: ({ lastName }) => /^\s*$/.test(lastName),
      message: "Please enter name.",
    },
    {
      name: "Invalid",
      func: (values) => values.lastName.length < 2,
      message: "Please enter valid name.",
    },
  ],
  platform: [
    {
      name: "Required",
      message: "Please make a selection.",
    },
  ],
  category: [
    {
      name: "Required",
      message: "Please make a selection.",
    },
  ],
  description: [
    {
      name: "Required",
      message: "Please enter description.",
    },
  ],
  redirectUrl: [
    {
      name: "Required",
      message: "Please enter redirect URL.",
    },
    {
      name: "Invalid",
      func: (values) => values.redirectUrl.match(/^1/) || !(values.redirectUrl.match(URL_REGEXP) || values.redirectUrl.match(IP_URL_REGEXP)),
      message: "Please enter valid redirect URL.",
    },
  ],
  phoneNumber: [
    {
      name: "Invalid",
      func: (values) => values.phoneNumber.match(/^1/) || !values.phoneNumber.match(PHONE_REGEXP),
      message: "Please enter valid phone number.",
    },
  ],
  facilities: [
    {
      name: "Invalid",
      func: ({ facilities }) => facilities && !/^\s*[0-9]+([0-9,\s]+)*[0-9]*\s*$/.test(facilities),
      message: "Facility ID must be numeric.",
    },
    {
      name: "Invalid",
      func: ({ facilities }) => facilities === undefined || !/^\s*[0-9]+(,[0-9]+)*\s*$/.test(facilities),
      message: "Use commas to separate IDs.",
    },
    {
      name: "Invalid",
      func: ({ facilities }) => facilities && !/^\s*[0-9]{1,9}(,[0-9]{1,9})*\s*$/.test(facilities),
      message: "Facility ID cannot be longer than 9 digits.",
    },
  ],
};

_.assign(COMMON_RULES, subscriptionRules);

export const getValidationRules = (fields, rules, validateAll = true) => {
  const validationRules = rules || COMMON_RULES;
  return (values) => {
    let errors = {};

    fields.every((field) => {
      if (!validationRules[field]) {
        return true;
      }

      const error = _.find(
        validationRules[field],
        // eslint-disable-next-line
        (rule) => {
          let invalid = (rule.name === "Required" && (!values[field] || (Array.isArray(values[field]) && !values[field].length))) || (rule.func && rule.func(values));
          if (rule.when) {
            invalid = (values[rule.when.field] === rule.when.value || values[rule.when.field]?.value === rule.when.value) && invalid;
          }

          return invalid;
        }
      );

      if (error) {
        errors[field] = error.funcMessage ? error.funcMessage(values) : error.message || error.name;
        return true;
      } else if (!validateAll) {
        errors = {};
        return false;
      }

      return true;
    });
    return errors;
  };
};

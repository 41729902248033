import React from "react";
import PropTypes from "prop-types";

import "./licenseForm.scss";
import LicenseSubscriptionFormComponent from "./LicenseSubscriptionFormComponent";
import LicenseFormComponent from "./LicenseFormComponent";

const LicenseOuterFormComponent = (props) => {
  const handleSubmit = (data) => {
    console.log(props.action);
    console.log(data);
    props.handleSubmit(props.action, data);
  };

  return (
    <div className="license">
      {props.page === 1 && <LicenseFormComponent {...props} handleSubmit={handleSubmit} />}
      {props.page === 2 && <LicenseSubscriptionFormComponent {...props} handleSubmit={handleSubmit} />}
    </div>
  );
};

LicenseOuterFormComponent.propTypes = {
  page: PropTypes.number.isRequired,
  isInvalid: PropTypes.bool,
  updateInvalid: PropTypes.func,
};

export default LicenseOuterFormComponent;

import { connect } from "react-redux";
// import { reduxForm, formValueSelector, isInvalid } from 'redux-form'

import COMPANY_RULES from "../validation/validationRules/company";
import { getValidationRules } from "../validation/validation";
// import { getAllCompanies } from './selectors/companySelectors'
import { COMPANY_SIZES, INDUSTRIES, COUNTRIES } from "../constants/companyConstants";
import { addCompanyAction, editCompanyAction } from "../redux/slice/companiesSlice";
import CompanyFormComponent from "../components/profile/Company/CompanyFormComponent";

// const _ = require('lodash');

export const checkForOtherFields = (company) => {
  const showOtherIndustryField = company.industry === "Other";
  const showOtherVerticalField = company.vertical === "Other";
  const showVerticalField = company.industry === "Fitness";

  return {
    otherFields: {
      industry: showOtherIndustryField,
      vertical: showOtherVerticalField,
      isFitnessIndustry: showVerticalField,
    },
  };
};

const mapDispatchToProps = {
  addCompanyAction,
  editCompanyAction,
};

const mergeProps = ({ company, initialValues, close, updateInvalid, action, ...stateProps }, { addCompanyAction, editCompanyAction }) => {
  let prevData = {};
  return {
    ...stateProps,
    action,
    company,
    initialValues,
    close,
    updateInvalid,
    addCompanyAction,
    editCompanyAction,
    validationRules: getValidationRules(validationRules, COMPANY_RULES),
  };
};

// const selector = formValueSelector('newCompany')
const mapStateToProps = (state, { company, action, close, initialValues, updateInvalid, disableConfirmButton, confirmButtonTitle, cancelButtonTitle }) => ({
  ...checkForOtherFields(company),
  action,
  company,
  close,
  initialValues,
  updateInvalid,
  disableConfirmButton,
  confirmButtonTitle,
  cancelButtonTitle,
});

export const validationRules = ["name", "platform", "companySize", "industry", "otherIndustry", "vertical", "otherVertical", "country", "address", "city", "state", "postalCode"];

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CompanyFormComponent);

import { connect } from "react-redux";
import { selectCompany } from "../redux/actions/companies";
import CompaniesComponent from "../components/profile/Companies/CompaniesComponent";

const mapStateToProps = (state, { company }) => ({
  company,
});

const mapDispatchToProps = {
  selectCompany,
};

const mergeProps = ({ company, ...stateProps }, { selectCompany }) => {
  return {
    ...stateProps,
    activePanel: null,
    selectCompany,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CompaniesComponent);

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SESSION } from "../Constants";
import { createOrganizations, editPersonalInfo, generateToken, sendForgotPasswordEmail, updateEmail, updateEmailInPendingFacilities, updatePassword, updateUserNameInPendingFacilities, validateEmailSignUp } from "../../firebase/cloud-functions";
import { signIn, signOut, signUp } from "../../firebase/auth";
import { toast } from "react-toastify";
import { getCompanies, selectCompany } from "../actions/companies";
import { getUser } from "../../firebase/user";
import { getToken, getUserId, isStaySignedIn } from "../../storage/localStorage";
import updateProfileApi from "../../api/updateProfileApi";
const prepareUser = ({ developerId, email, firstName, lastName }) => ({
  ...(developerId && { id: developerId }),
  email,
  firstName,
  lastName,
});

// INITIAL STATE
const initialState = SESSION.INITIAL_STATE;

// CREATE ACTIONS
export const loginAction = createAsyncThunk(SESSION.ACTION.LOGIN_SESSION, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const { email, password, staySignedIn } = payload;
    const { user, token } = await signIn(email, password, staySignedIn);
    const companies = await getCompanies(user.companies ? Object.keys(user.companies).join(",") : "");
    const companyId = await selectCompany(user.companies ? user.companies[Object.keys(user.companies)[0]].companyId : "");
    return { user, companies, companyId, token, staySignedIn };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const logoutAction = createAsyncThunk(SESSION.ACTION.LOGOUT_SESSION, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    await signOut();
    return SESSION.INITIAL_STATE;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const registerAction = createAsyncThunk(SESSION.ACTION.REGISTER, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const { errors, user, token } = await signUp(payload);
    if (errors) throw errors;
    toast(SESSION.RESPONSE.MESSAGE.SUCCESSFUL_AUTH_MESSAGE, { className: "sign-up-toast" });

    return { user, token };
  } catch (error) {
    console.log("************* ERRR REGISTER: error: ", JSON.stringify(error));
    return rejectWithValue(error);
  }
});

export const registerEmailAction = createAsyncThunk(SESSION.ACTION.REGISTER_EMAIL, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const email = payload.input && payload.input.email;
    let user = null;
    if (email) {
      const response = await validateEmailSignUp({ email });
      if (!response.ok) {
        const error = await response.json();
        throw error;
      } else user = payload.input;
    }

    return { user, onFulfilled: payload.onFulfilled };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const sendForgotPasswordEmailAction = createAsyncThunk(SESSION.ACTION.SEND_FORGOT_PASSWORD_EMAIL, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const email = payload.input && payload.input.email;
    let error = null;
    if (email) {
      const response = await sendForgotPasswordEmail({ email });
      if (!response.ok) {
        error = await response.text();
        const errorObj = { email: error };
        throw errorObj;
      } else {
        toast(SESSION.RESPONSE.MESSAGE.SUCCESSFUL_SEND_EMAIL_MESSAGE, { className: "sign-up-toast" });
        return { email, onFulfilled: payload.onFulfilled };
      }
    } else if (payload.cancel === true) {
      return { onFulfilled: payload.onFulfilled };
    } else {
      error = { email: SESSION.RESPONSE.MESSAGE.INTERNAL_SERVER_ERROR };
      throw error;
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAuthInfoAction = createAsyncThunk(SESSION.ACTION.GET_AUTH_INFO, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const user = await getUser(getUserId());
    const companies = await getCompanies(user.companies ? Object.keys(user.companies).join(",") : "");
    const companyId = await selectCompany(user.companies ? user.companies[Object.keys(user.companies)[0]].companyId : "");
    const token = await getToken();
    return { user, companies, companyId, token, staySignedIn: isStaySignedIn() };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updatePersonalInfoAction = createAsyncThunk(SESSION.ACTION.UPDATE_PERSONAL_INFO, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const { user } = payload;

    let __developer__ = { ...prepareUser(user) };
    if (user.developerId) await updateProfileApi.update(__developer__);
    let response = await editPersonalInfo(user.uid, user);
    if (response.ok) {
      dispatch(getAuthInfoAction());
      toast(`${user.firstName} ${user.lastName} ${SESSION.RESPONSE.MESSAGE.SUCCESSFUL_UPDATE_TEAM_MEMBER}`);
      updateUserNameInPendingFacilities({ developer: __developer__ });
    } else if (response.status === SESSION.RESPONSE.STATUS_CODE.UNPROCESSABLE_ENTITY) {
      const error = await response.text();
      throw error;
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updatePasswordAction = createAsyncThunk(SESSION.ACTION.UPDATE_PASSWORD, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    console.log("***** updatePasswordAction payload: ", payload);
    const { currentPassword, email, password } = payload;
    if (currentPassword === password) {
      throw { password: SESSION.RESPONSE.MESSAGE.SAME_PWD_MESSAGE };
    }

    let response = await signIn(email, currentPassword);
    if (response) {
      let result = await updatePassword(payload);
      if (result.ok) {
        //sign in again with the new password to reauthenticate the user
        await signIn(email, password);
        toast(SESSION.RESPONSE.MESSAGE.SUCCESSFUL_PWD_UPDATE_MESSAGE);
      }
    }
  } catch (error) {
    return rejectWithValue({ currentPassword: SESSION.RESPONSE.MESSAGE.FAIL_PWD_MESSAGE });
  }
});

export const updateEmailAction = createAsyncThunk(SESSION.ACTION.UPDATE_EMAIL, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const { userId, prevEmail, email, password, firstName, lastName, developerId } = payload;
    //reauthenticate the user before changing the password
    let response = await signIn(prevEmail, password);
    if (response) {
      let fbResponse = await updateEmail({ userId, email });

      if (fbResponse.ok) {
        if (developerId) {
          let developer = {
            ...prepareUser({ developerId, firstName, lastName, email }),
          };
          await fetch(updateProfileApi.update(developer));
        }
        await signIn(email, password);
        dispatch(getAuthInfoAction());
        toast(SESSION.RESPONSE.MESSAGE.SUCCESSFUL_CHANGE_EMAIL_MESSAGE);
        await updateEmailInPendingFacilities({ prevEmail, email });
      } else throw { message: SESSION.RESPONSE.MESSAGE.FAIL_EMAIL_EXISTS_MESSAGE };
    }
  } catch (error) {
    console.log("***** updateEmailAction.error BEFORE: ", error.message);
    if (error.message !== SESSION.RESPONSE.MESSAGE.FAIL_EMAIL_EXISTS_MESSAGE) {
      error = { password: SESSION.RESPONSE.MESSAGE.FAIL_PWD_MESSAGE };
    }
    console.log("***** updateEmailAction.error AFTER: ", error);
    return rejectWithValue(error);
  }
});

export const generateZendeskTokenAction = createAsyncThunk(SESSION.ACTION.GENERATE_ZENDESK_TOKEN, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const { userId } = payload;
    console.log("***** generateZendeskTokenAction.userId: ", userId);
    let response = await createOrganizations(userId);
    const response_companies = await response.json();
    response = await generateToken(response_companies.companies);
    const zendeskTokenJSON = await response.json();
    console.log("***** generateZendeskTokenAction.zendeskTokenJSON: ", zendeskTokenJSON);
    window.location.href = `https://haloapisupport.zendesk.com/access/jwt?jwt=${zendeskTokenJSON["token"]}`;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const selectSessionCompanyAction = createAsyncThunk(SESSION.ACTION.SELECT_COMPANY, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const companyId = payload.companyId.value;
    await selectCompany(companyId);
    return { companyId, company: getState().session.companies.find((c) => c.firebaseId === companyId) };
  } catch (error) {
    return rejectWithValue(error);
  }
});

// CREATE SLICE
const sessionSlice = createSlice({
  name: SESSION.SLICE,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAuthInfoAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(getAuthInfoAction.fulfilled, (state, action) => {
      console.log("***** getAuthInfoAction.fulfilled: ", action.payload);
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.companies = action.payload.companies;
      state.companyId = action.payload.companyId;
      state.company = action.payload.companies.find((c) => c.firebaseId === action.payload.companyId);
      state.staySignedIn = action.payload.staySignedIn;
      state.isAuthenticated = true;
    });

    builder.addCase(getAuthInfoAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = { email: SESSION.RESPONSE.MESSAGE.FAIL_AUTH_MESSAGE };
    });

    builder.addCase(loginAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(loginAction.fulfilled, (state, action) => {
      console.log("***** loginAction.fulfilled: ", action.payload);
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.companies = action.payload.companies;
      state.companyId = action.payload.companyId;
      state.company = action.payload.companies.find((c) => c.firebaseId === action.payload.companyId);
      state.staySignedIn = action.payload.staySignedIn;
      state.isAuthenticated = true;
    });

    builder.addCase(loginAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = { email: SESSION.RESPONSE.MESSAGE.FAIL_AUTH_MESSAGE };
    });

    builder.addCase(logoutAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(logoutAction.fulfilled, (state, action) => {
      state = { ...action.payload, fulfilled: true };
    });

    builder.addCase(logoutAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = { email: SESSION.RESPONSE.MESSAGE.FAIL_AUTH_MESSAGE };
    });

    builder.addCase(registerEmailAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(registerEmailAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
      if (action.payload.user) state.user = action.payload.user; // to be verified
      if (action.payload.onFulfilled) {
        Object.keys(action.payload.onFulfilled).forEach((k) => (state[k] = action.payload.onFulfilled[k]));
      }
      state.isAuthenticated = false;
    });

    builder.addCase(registerEmailAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
    });

    builder.addCase(sendForgotPasswordEmailAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(sendForgotPasswordEmailAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
      if (action.payload.onFulfilled) {
        Object.keys(action.payload.onFulfilled).forEach((k) => (state[k] = action.payload.onFulfilled[k]));
      }
      state.isAuthenticated = false;
    });

    builder.addCase(sendForgotPasswordEmailAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
    });

    builder.addCase(registerAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(registerAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.showCookiePolicyBanner = true;
      state.isAuthenticated = true;
    });

    builder.addCase(registerAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
      state.token = null;
    });

    builder.addCase(updatePersonalInfoAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(updatePersonalInfoAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(updatePersonalInfoAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
    });

    builder.addCase(updatePasswordAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(updatePasswordAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(updatePasswordAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
    });

    builder.addCase(updateEmailAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(updateEmailAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(updateEmailAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
    });

    builder.addCase(generateZendeskTokenAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(generateZendeskTokenAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(generateZendeskTokenAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
    });

    builder.addCase(selectSessionCompanyAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(selectSessionCompanyAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      console.log("***** selectSessionCompanyAction.fulfilled: ", action.payload);
      state.company = action.payload.company;
      state.companyId = action.payload.companyId;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(selectSessionCompanyAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
    });
  },
});

// CREATE REDUCER
const sessionReducer = sessionSlice.reducer;

export default sessionReducer;

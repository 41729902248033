import { PROFILE_MENU } from "../core/MenuComponent/MenuConstants";
import DropDownComponent from "../core/DropDownComponent/DropDownComponent";

import { Button, Container, Image, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { LANDING_PATH, HOME_PATH, DOCUMENTATION_PATH, SIGN_UP_PATH, SIGN_IN_PATH, USECASES_PATH, LICENSES_PATH, SUPPORT_PATH, PROFILE_PATH, OVERVIEW_PATH, ADMIN_PATH, GUIDES_PATH } from "../../constants/routes";

import "./header.scss";
import { useLocation, useNavigate } from "react-router-dom";

import CONFIG from "../../config/config";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../redux/slice/sessionSlice";
import { getUserId } from "../../storage/localStorage";

const logo = require("./lifefitness-logo.png");

const PROFILE = {
  ...PROFILE_MENU,
  "api-documentation": {
    title: "Documentation",
    url: OVERVIEW_PATH,
  },
  logOut: {
    title: "Log Out",
    url: LANDING_PATH,
  },
};

const HeaderComponent = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, isEntryPoint } = props;
  const [items, setItems] = useState(
    Object.keys(PROFILE).map((path) => {
      const { title, url } = PROFILE[path];
      return {
        title,
        url: url || `/auth/profile/${path}`,
      };
    })
  );
  const path = location?.pathname;

  const isLandingPage = path === LANDING_PATH;
  const isSignInPage = path === SIGN_IN_PATH;
  const isSignUpPage = path === SIGN_UP_PATH;
  const isUseCasesPage = path == USECASES_PATH;
  const isDocsPage = path.startsWith(DOCUMENTATION_PATH);
  const isGuidesPage = path.startsWith(GUIDES_PATH);
  const isLicensesPage = path.startsWith(LICENSES_PATH);
  const isSupportPage = path.startsWith(SUPPORT_PATH);

  const goToSignUpPage = () => navigate(SIGN_UP_PATH);
  const goToSignInPage = () => navigate(SIGN_IN_PATH);
  const goToHomePage = () => navigate(HOME_PATH);
  const goToLandingPage = () => navigate(LANDING_PATH);
  const goToUsecasesPage = () => navigate(USECASES_PATH);
  const goToDocumentationPage = () => navigate(OVERVIEW_PATH);
  const goToLicensesPage = () => navigate(LICENSES_PATH);
  const goToGuidesPage = () => navigate(`${GUIDES_PATH}/introduction`);
  const goToSupportPage = () => navigate(SUPPORT_PATH);
  const goToAdminPage = () => navigate(ADMIN_PATH);
  var page = "/" + window.location.pathname.split("/")[1];
  if (page === PROFILE_PATH) {
    page += "/" + window.location.pathname.split("/")[2];
  }

  const openSandbox = (e) => {
    e.preventDefault();
    window.open(CONFIG.restURL, "_blank", "noopener,noreferrer");
  };

  const handleNavSelect = (eventKey) => {
    const __url__ = eventKey.split("|")[1];
    if (__url__ === LANDING_PATH) {
      dispatch(logoutAction());
      window.location.href = LANDING_PATH;
    } else navigate(__url__);
  };

  return (
    <div id="header" className={`Header-component ${path?.indexOf("api") > -1 && "sticky"}`}>
      <Navbar className={isLandingPage || isSignInPage || isSignUpPage ? "initialPage" : ""}>
        <Container className="haloLogoCursor">
          {user ? (
            <Navbar.Brand>
              <Image src={logo} onClick={goToHomePage} className="navbar-brand" />
              {/* <Image src={logo} /> */}
            </Navbar.Brand>
          ) : (
            <Navbar.Brand>
              <Image src={logo} onClick={goToLandingPage} className="navbar-brand" />
              {/* <Image src={logo} /> */}
            </Navbar.Brand>
          )}
        </Container>
        <Nav className="center-actions">
          <Nav.Item className="navItem">
            <Nav.Link className={isUseCasesPage && "underline"} onClick={goToUsecasesPage}>
              Use Cases
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="navItem">
            <Nav.Link className={isDocsPage && "underline"} onClick={goToDocumentationPage}>
              Docs
            </Nav.Link>
          </Nav.Item>
          {user && (
            <Nav.Item className="navItem">
              <Nav.Link className={isLicensesPage && "underline"} onClick={goToLicensesPage}>
                Licenses
              </Nav.Link>
            </Nav.Item>
          )}
          <Nav.Item className="navItem">
            <Nav.Link onClick={openSandbox}>Sandbox</Nav.Link>
          </Nav.Item>
          <Nav.Item className="navItem">
            <Nav.Link className={isGuidesPage && "underline"} onClick={goToGuidesPage}>
              Guides
            </Nav.Link>
          </Nav.Item>
          {user && (
            <Nav.Item className="navItem">
              <Nav.Link className={isSupportPage && "underline"} onClick={goToSupportPage}>
                Support
              </Nav.Link>
            </Nav.Item>
          )}
          {user && user.admin && (
            <Nav.Item className="navItem">
              <Nav.Link onClick={goToAdminPage}>Admin</Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        {!isLandingPage && !isEntryPoint && user && user.uid === getUserId() && (
          <Nav onSelect={handleNavSelect}>
            <DropDownComponent
              title={
                <span className="profile">
                  <span className="profile-icon" />
                  Welcome, {`${user.firstName}`}
                </span>
              }
              id="profile-dropdown"
              className="profile-dropdown"
            >
              {items.map(({ accessDenied, url, title }, index) => (
                <NavDropdown.Item key={index} eventKey={`3|${url}`}>
                  {title}
                </NavDropdown.Item>
              ))}
            </DropDownComponent>
          </Nav>
        )}
        {(isLandingPage || isEntryPoint || !user || !getUserId()) && (
          <div className="login-signup-buttons">
            {!isSignInPage && !isSignUpPage && <Button onClick={goToSignUpPage}>Sign Up</Button>}
            {!isSignInPage && !isSignUpPage && <span>&nbsp;&nbsp;</span>}
            {!isSignInPage && !isSignUpPage && (
              <Button onClick={goToSignInPage} className="dark">
                Log In
              </Button>
            )}
            {isSignInPage && (
              <Button className="signInUpPageOnly" onClick={goToSignUpPage}>
                Sign Up Now
              </Button>
            )}
            {isSignUpPage && (
              <Button className="signInUpPageOnly" onClick={goToSignInPage}>
                Log In
              </Button>
            )}
          </div>
        )}
      </Navbar>
    </div>
  );
};

export default HeaderComponent;

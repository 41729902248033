const { TEAM_MEMBERS_PATH } = require("../constants/routes");

const BASELINE_INITIAL_STATE = {
  pending: false,
  fulfilled: false,
  rejected: false,
  error: null,
};

module.exports = {
  COMPANIES: {
    ACTION: {
      ADD_COMPANY: "DevPortal/addCompany",
      EDIT_COMPANY: "DevPortal/editCompany",
      GET_COMPANIES: "DevPortal/getCompanies",
      INIT_COMPANIES: "DevPortal/initCompanies",
      SELECT_COMPANY: "DevPortal/selectCompany",
      REMOVE_CURRENT_COMPANY: "DevPortal/removeCurrentCompany",
    },
    INITIAL_STATE: {
      ...BASELINE_INITIAL_STATE,
      items: [],
      companyId: {},
      currentCompany: {},
    },
    RESPONSE: {
      MESSAGE: {
        SUCCESSFUL_CREATE_COMPANY: "The company has been added successfully.",
        SUCCESSFUL_UPDATE_COMPANY: "The company has been updated successfully.",
      },
      STATUS_CODE: {
        INTERNAL_SERVER_ERROR_CODE: 500,
      },
    },
    SLICE: "DevPortalCompanies",
  },
  COMPATIBILITY: {
    ACTION: {
      GET_COMPATIBILITY_MAPPING: "DevPortal/getCompatibilityMapping",
    },
    INITIAL_STATE: {
      ...BASELINE_INITIAL_STATE,
      apis: {},
      commonApis: {},
      consoleSpecificApis: {},
    },
    SLICE: "DevPortalCompatibility",
  },
  DATA_TYPES: {
    ACTION: {
      GET_DATA_TYPES: "DevPortal/getDataTypes",
    },
    INITIAL_STATE: {
      ...BASELINE_INITIAL_STATE,
      items: [],
      activeKeys: {},
    },
    SLICE: "DevPortalDataTypes",
  },
  GRIDS: {
    ACTION: {
      DELETE_GRID: "DevPortal/deleteGrid",
      SET_GRID: "DevPortal/setGrid",
      SET_GRID_ITEMS: "DevPortal/setGridItems",
      SET_SORTING: "DevPortal/setSorting",
      SET_PAGINATION: "DevPortal/setPagination",
      SELECT_GRID_ITEM: "DevPortal/selectGridItem",
    },
    INITIAL_STATE: {
      GRID: {
        ...BASELINE_INITIAL_STATE,
        grids: {},
      },
      GRID_ITEM: {
        selectedItems: {},
        items: [],
        sortBy: {
          props: "",
          directions: ["asc"],
        },
        pagination: {
          enabled: true,
          activePage: 1,
          totalPages: 1,
          itemsPerPage: 5,
        },
      },
    },
    SLICE: "DevPortalGridStore",
  },
  LICENSES: {
    ACTION: {
      ADD_FACILITIES: "DevPortal/addFacilities",
      ADD_LICENSE: "DevPortal/addLicense",
      CHANGE_LICENSE: "DevPortal/changeLicense",
      DELETE_LICENSE: "DevPortal/deleteLicense",
      GET_LICENSE: "DevPortal/getLicense",
      GET_LICENSES: "DevPortal/getLicenses",
      GET_PENDING_FACILITIES_ADMIN: "DevPortal/getPendingFacilitiesAdmin",
      INIT_LICENSES: "DevPortal/initLicenses",
      MOVE_LICENSE: "DevPortal/moveLicense",
      REMOVE_FACILITIES: "DevPortal/removeFacilities",
      UPDATE_PENDING_FACILITIES: "DevPortal/updatePendingFacilities",
    },
    INITIAL_STATE: {
      ...BASELINE_INITIAL_STATE,
      license: null,
      items: [],
      licenseId: null,
      pendingFacilities: null,
    },
    RESPONSE: {
      MESSAGE: {
        CREATED: (name, licenseNumber) => `${name} was successfully created. Your license number is ${licenseNumber}. You can download our client library and use this license to integrate with us. For more info please refer to our docs.`,
        DELETED: (name) => `${name} was successfully deleted.`,
        EDITED: (name) => `${name} was successfully edited.`,
        MOVED: (name) => `${name} was successfully moved to Production. To edit the app, expand the production section below.`,
      },
    },
    SLICE: "DevPortalLicenses",
  },
  SESSION: {
    ACTION: {
      GENERATE_ZENDESK_TOKEN: "DevPortal/generateZendeskToken",
      GET_AUTH_INFO: "DevPortal/getAuthInfo",
      LOGIN_SESSION: "DevPortal/login",
      LOGOUT_SESSION: "DevPortal/logout",
      REGISTER: "DevPortal/register",
      REGISTER_EMAIL: "DevPortal/registerEmail",
      SELECT_COMPANY: "DevPortal/selectCompany",
      SEND_FORGOT_PASSWORD_EMAIL: "DevPortal/sendForgotPasswordEmail",
      UPDATE_EMAIL: "DevPortal/updateEmail",
      UPDATE_PASSWORD: "DevPortal/updatePassword",
      UPDATE_PERSONAL_INFO: "DevPortal/updatePersonalInfo",
    },
    INITIAL_STATE: {
      ...BASELINE_INITIAL_STATE,
      isAuthenticated: false,
      token: "",
      user: {},
      companies: null,
      companyId: null,
      company: null,
      showCookiePolicyBanner: false,
      showBetaMessagingBanner: true,
      staySignedIn: false,
    },
    RESPONSE: {
      MESSAGE: {
        SUCCESSFUL_AUTH_MESSAGE: "Thank you for signing up for Developer Connect.",
        FAIL_AUTH_MESSAGE: "Sorry, that username or password is incorrect.",
        FAIL_EMAIL_EXISTS_MESSAGE: "Email id already exists.",
        FAIL_EMAIL_MISSING: "Missing input email address.",
        FAIL_EMAIL_PWD_MESSAGE: "Email/Password incorrect",
        FAIL_PWD_MESSAGE: "Sorry, that password is incorrect.",
        SAME_PWD_MESSAGE: "Please use a new password.",
        SUCCESSFUL_CHANGE_EMAIL_MESSAGE: "Your email has been updated successfully.",
        SUCCESSFUL_PWD_UPDATE_MESSAGE: "Password has been updated successfully.",
        SUCCESSFUL_SEND_EMAIL_MESSAGE: "Reset password instructions have been sent to your email.",
        SUCCESSFUL_UPDATE_TEAM_MEMBER: " was successfully updated.",
        INTERNAL_SERVER_ERROR: "Internal Server Error. Please contact your System Administrator.",
      },
      STATUS_CODE: {
        NOT_FOUND: 404,
        UNPROCESSABLE_ENTITY: 422,
        FORBIDDEN: 403,
      },
    },
    SLICE: "DevPortalSession",
  },
  STORAGE: {
    ACTION: {
      GET_ARCHIVES: "DevPortal/getArchives",
    },
    INITIAL_STATE: {
      ...BASELINE_INITIAL_STATE,
      files: {},
      sdkVersion: {},
    },
    SLICE: "DevPortalStorage",
  },
  TEAM_MEMBERS: {
    ACTION: {
      CREATE_TEAM_MEMBER: "DevPortal/createTeamMember",
      EDIT_TEAM_MEMBER: "DevPortal/editTeamMember",
      GET_TEAM_MEMBERS: "DevPortal/getTeamMembers",
      INIT_TEAM_MEMBERS: "DevPortal/initTeamMembers",
      REMOVE_TEAM_MEMBER: "DevPortal/removeTeamMember",
      RESEND_INVITATION: "DevPortal/resendInvitation",
    },
    INITIAL_STATE: {
      ...BASELINE_INITIAL_STATE,
      items: [],
    },
    SESSION: {
      RESPONSE: {
        MESSAGE: {
          SUCCESSFUL_CREATE_TEAM_MEMBER: "Team member has been added successfully.",
          SUCCESSFUL_UPDATE_TEAM_MEMBER: " was successfully updated.",
          SUCCESSFUL_DELETE_TEAM_MEMBER: " was successfully deleted.",
        },
        STATUS_CODE: {
          UNPROCESSABLE_ENTITY_ERROR_CODE: 422,
        },
      },
    },
    SLICE: "DevPortalTeamMembers",
  },
  TOS: {
    ACTION: {
      TOS_UPDATE: "DevPortal/tosUpdate",
    },
    INITIAL_STATE: {
      ...BASELINE_INITIAL_STATE,
      isUpdated: false,
    },
    MILLISECONDS_IN_SIXTY_DAYS: 60 * 24 * 60 * 60 * 1000,
    SLICE: "DevPortalTOS",
  },
  PARTNERAPI_DB_KEYS: {
    COMPANIES: "COMPANIES",
    LICENSES: "LICENSES",
    METADATA: "metadata",
    PENDING_FACILITIES: "PENDING_FACILITIES",
    USERS: "USERS",
  },
};

import "./signUp.scss";
import LoadingComponent from "../../core/LoadingComponent/LoadingComponent";
import ReactGA from "react-ga";
import { useEffect, useState } from "react";
import SignUpPersonalComponent from "./SignUpPersonalComponent";
import { useDispatch, useSelector } from "react-redux";
import { registerAction, registerEmailAction } from "../../../redux/slice/sessionSlice";
import SignUpCompanyComponent from "./SignUpCompanyComponent";
//import { param } from "jquery";
import { Navigate, useNavigate } from "react-router-dom";
import { HOME_PATH } from "../../../constants/routes";

const SignUpComponent = (props) => {
  const dispatch = useDispatch();
  const sessionState = useSelector((__state__) => __state__.session);
  const [company, setCompany] = useState({});
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "Sign Up Page" });
    ReactGA.pageview(window.location.pathname);

    return () => {
      ReactGA.event({
        category: "Sign Up Company Component",
        action: "After clicking Next button",
        label: "Button",
      });
    };
  }, []);

  const nextPageHandler = () => {
    setPage(page + 1);
  };

  const checkEmailExists = (params) => {
    dispatch(registerEmailAction({ input: params, onFulfilled: { signUpComponent: { page: 2 } } }));
  };

  const onSubmitHandler = ({ values }) => {
    const formattedValues = { ...values };
    // dispatch registerAction here...
    if (formattedValues.industry.value !== "Fitness") {
      formattedValues.vertical = null;
      formattedValues.otherVertical = null;
    } else if (formattedValues.vertical.value !== "Other") {
      formattedValues.otherVertical = null;
    }

    if (formattedValues.industry.value !== "Other") {
      formattedValues.otherIndustry = null;
    }

    getSelectValue(formattedValues, "country");
    getSelectValue(formattedValues, "platform", true);
    getSelectValue(formattedValues, "companySize");
    getSelectValue(formattedValues, "industry");
    getSelectValue(formattedValues, "vertical");

    dispatch(registerAction({ ...sessionState.user, ...formattedValues }));
  };

  const previousPageHandler = (values) => {
    ReactGA.event({
      category: "Sign Up Company Component",
      action: "Clicked Back",
      label: "Icon",
    });

    const formattedValues = { ...values };
    getSelectValue(formattedValues, "country");
    getSelectValue(formattedValues, "platform", true);
    getSelectValue(formattedValues, "companySize");
    getSelectValue(formattedValues, "industry");
    getSelectValue(formattedValues, "vertical");

    dispatch(
      registerEmailAction({
        onFulfilled: {
          company: {
            ...formattedValues,
          },
          signUpComponent: { page: 1 },
        },
      })
    );
  };

  const getSelectValue = (values, name, isArray = false) => {
    if (isArray && Array.isArray(values[name]) && values[name].length) {
      values[name] = values[name].map((e) => e.value).join(",");
    } else if (values[name] && values[name].value) values[name] = values[name].value;
  };
  //const { onCancel, submitting, onSubmit } = props;
  return (
    <>
      {sessionState.fulfilled && sessionState.token && <Navigate to={HOME_PATH} />}
      {!sessionState.token && (
        <div className="sign-up container">
          <h2>Sign Up</h2>
          <h4>({page} of 2)</h4>
          {(!sessionState.signUpComponent?.page || (sessionState.signUpComponent?.page && sessionState.signUpComponent?.page === 1)) && <SignUpPersonalComponent onSubmit={checkEmailExists} />}
          {sessionState.signUpComponent?.page && sessionState.signUpComponent?.page === 2 && <SignUpCompanyComponent onSubmit={onSubmitHandler} prevPage={previousPageHandler} />}
        </div>
      )}
    </>
  );
};

export default SignUpComponent;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NavDropdown } from "react-bootstrap";
import ReactGA from "react-ga";

import LoadingComponent from "../../core/LoadingComponent/LoadingComponent";
import GridContainer from "../../../containers/GridContainer";
import ModalComponent from "../../core/ModalComponent/ModalComponent";
import TeamMemberContainer from "../../../containers/TeamMemberContainer";
import TooltipComponent from "../../core/TooltipComponent/TooltipComponent";
import ROLES from "../TeamMember/TeamMemberConstants";
import DropDownComponent from "../../core/DropDownComponent/DropDownComponent";
import { MEMBERS_MENU, MEMBERS_MENU_INVITATION } from "../../core/MenuComponent/MenuConstants";
import FieldGroupComponent from "../../core/FieldGroupComponent/FieldGroupComponent";

import { HOME_PATH } from "../../../constants/routes";

import "./teamMembers.scss";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-final-form";
import { initTeamMembersAction, removeTeamMemberAction, resendInvitationAction } from "../../../redux/slice/teamMembersSlice";
import { setGridItemsAction } from "../../../redux/slice/gridSlice";

const _ = require("lodash");

const ACTIONS = {
  add: "add",
  update: "update",
  delete: "delete",
};
let GRID_PROPS = [];

export const getTooltip = (container) => (
  <TooltipComponent
    container={container}
    overlayContent={
      <span>
        <b>Members can:</b>
        <ol>
          {ROLES.member.map((permission, index) => (
            <li key={index}>{permission}</li>
          ))}
        </ol>
        <b>Admin users can:</b>
        <ol>
          {ROLES.admin.map((permission, index) => (
            <li key={index}>{permission}</li>
          ))}
        </ol>
      </span>
    }
    children={<span className="info-icon-members" />}
    className={"members-tooltip"}
  />
);

const TeamMembersComponent = (props) => {
  const dispatch = useDispatch();
  const sessionState = useSelector((__state__) => __state__.session);
  const teamMembersState = useSelector((__state__) => __state__.teamMembers);
  const [modalState, setModalState] = useState({
    modalTitle: "",
    modalBody: "",
    modalConfirmButtonTitle: "Yes",
    modalOnConfirm: () => {},
    showModal: false,
    showAllMembersLink: true,
    nullUser: !(props.user && props.user.uid),
    members: teamMembersState.items,
    search: "",
  });

  useEffect(() => {
    setGridProps(props);
  }, []);

  const setGridProps = (props) => {
    if (modalState.nullUser && props.user && props.user.uid) {
      setModalState({
        ...modalState,
        nullUser: false,
      });
    }
    GRID_PROPS = [
      {
        id: "member-icon",
        title: "Member",
        className: "member-icon",
        getter: () => {
          return (
            <div className="member-icon-container">
              <span />
            </div>
          );
        },
      },
      {
        title: "Name",
        className: "name",
        getter: (item) => {
          return (
            <div className="licenses-team-members">
              {item.firstName} {item.lastName} {props.user.uid === item.uid && <span> (me)</span>}
              {item.companies[props.currentCompanyId] && (item.companies[props.currentCompanyId].status === "Pending" ? <p className="pending">Pending</p> : item.companies[props.currentCompanyId].isAdmin ? <p>Admin</p> : <p>Member</p>)}
            </div>
          );
        },
      },
      {
        id: "email",
        title: "Email",
        className: "email",
      },
      /*{
                id: 'role',
                title: () => <div>Role {getTooltip()}</div>,
                className: 'role',
            },
            {
                title: 'Status',
                className: 'status',
                getter: (member) => {
                    const isPending = member.status === 'Pending'
                    return <div className={isPending ? 'pending' : ''}>
                        {member.status}
                        {isPending && <Image
                            src={EMAIL_ICON}
                            onClick={() => {
                                const gridWidth = document.getElementById('team-members').offsetWidth;
                                const firstColumnWidth = document.getElementsByClassName('name')[0].offsetWidth;
                                const lastColumnWidth = document.getElementsByClassName('ellipsis')[0].offsetWidth;
                                const notificationElement = document.getElementById(`resendEmailSection-${member.uid}`);

                                notificationElement.style.width = `${gridWidth - firstColumnWidth}px`;
                                notificationElement.style.left = `-${gridWidth - firstColumnWidth - lastColumnWidth}px`;
                                notificationElement.classList.add('active');
                            }}
                        />}
                    </div>
                },
            },
            {
                title: 'Edit',
                className: 'buttons',
                getter: (member) => <div>
                    <div>
                        {props.user.uid !== member.uid && <Button
                            className="edit"
                            onClick={() => showModal(ACTIONS.update, member)}
                        />}
                        {props.user.uid !== member.uid && <Button
                            className="remove"
                            onClick={() => showModal(ACTIONS.delete, member)}
                        />}
                    </div>
                    <div id={`resendEmailSection-${member.uid}`} className="notification">
                        <div className="notification-panel">
                            <Image src={WARNING_ICON} />
                            <div className="text">
                                <span>Would you like to resend the invitation email?</span>
                            </div>
                            <div className="buttons">
                                <Button
                                    className="edit"
                                    onClick={async () => {
                                        await props.resendInvitation(member.uid);
                                        removeNotificationElement(member);
                                    }}
                                >Resend</Button>
                                <Button
                                    className="remove"
                                    onClick={() => removeNotificationElement(member)}
                                >Cancel</Button>
                            </div>
                        </div>
                    </div>
                </div>,
            },*/
      {
        title: "Ellipsis",
        className: "ellipsis",
        getter: (member) => {
          const items = [];
          let TEAMMEMBER_ACTION = {};
          const isPending = member.companies[props.currentCompanyId] ? member.companies[props.currentCompanyId].status === "Pending" : false;
          const isloggedInUser = props.user.uid === member.uid;

          const displayEllipsis = (!isloggedInUser && props.isUserAdmin) || (!isloggedInUser && isPending);
          if (isPending && props.isUserAdmin) {
            TEAMMEMBER_ACTION = {
              ...MEMBERS_MENU,
              ...MEMBERS_MENU_INVITATION,
            };
          } else if (isPending && !props.isUserAdmin) {
            TEAMMEMBER_ACTION = {
              ...MEMBERS_MENU_INVITATION,
            };
          } else {
            TEAMMEMBER_ACTION = {
              ...MEMBERS_MENU,
            };
          }

          Object.keys(TEAMMEMBER_ACTION).forEach((path) => {
            const { title, action } = TEAMMEMBER_ACTION[path];

            items.push({
              title,
              action,
            });
          });
          return (
            <div>
              {displayEllipsis && (
                <DropDownComponent eventKey={1} className="member-dropdown" title={<span className="ellipsis-span" />} id="members-menu">
                  {items.map(({ title, action }, index) => (
                    <NavDropdown.Item
                      className={action}
                      key={index}
                      eventKey={title}
                      onClick={() => {
                        memberAction(action, member);
                      }}
                    >
                      {title}
                    </NavDropdown.Item>
                  ))}
                </DropDownComponent>
              )}
            </div>
          );
        },
      },
    ];
  };

  const removeNotificationElement = ({ uid }) => {
    const notificationElement = document.getElementById(`resendEmailSection-${uid}`);

    notificationElement.style.left = "220px";
  };

  const removeTeamMemberOnConfirm = (member) => {
    dispatch(
      removeTeamMemberAction({
        members: teamMembersState.items,
        member: {
          ...member,
          currentCompanyId: sessionState.companyId,
        },
      })
    );
    closeModal();
  };

  const show = (action, member) => {
    let state = {};

    ReactGA.event({
      category: "TeamMembers Component",
      action: "Clicked Add Icon",
      label: "Icon",
    });

    if (action === ACTIONS.delete) {
      state = {
        modalTitle: "Delete Team Member?",
        modalBody: `Are you sure you want to delete ${member.firstName} ${member.lastName} ?`,
        modalConfirmButtonTitle: "Delete",
        modalOnConfirm: () => {
          removeTeamMemberOnConfirm(member);
        },
        disableConfirmButton: false,
        hideFooter: false,
      };
    } else if (action === ACTIONS.add) {
      state = {
        modalTitle: `${_.capitalize(action)} Team Member`,
        modalBody: renderCreateForm("Create", member),
        modalConfirmButtonTitle: "Create",
        modalOnConfirm: props.submitMemberForm,
        disableConfirmButton: true,
        hideFooter: true,
      };
    } else {
      state = {
        modalTitle: `${_.capitalize(action)} Team Member`,
        modalBody: renderCreateForm(`${_.capitalize(action)}`, member),
        modalConfirmButtonTitle: `${_.capitalize(action)}`,
        modalOnConfirm: props.submitMemberForm,
        disableConfirmButton: true,
        hideFooter: true,
      };
    }

    setModalState({
      ...modalState,
      ...state,
      showModal: true,
      action,
    });
  };

  const updateInvalid = (invalid) => {
    setModalState({
      ...modalState,
      disableConfirmButton: invalid,
    });
  };

  const closeModal = () => {
    ReactGA.event({
      category: "TeamMembers Component",
      action: "Clicked Close Modal Icon",
      label: "Icon",
    });
    dispatch(initTeamMembersAction());
    setModalState({
      ...modalState,
      showModal: false,
    });
  };

  const memberAction = (action, member) => {
    const { companyId: currentCompanyId } = sessionState;
    if (action === "resend_invitation") {
      dispatch(resendInvitationAction({ uid: member.uid, currentCompanyId }));
    } else {
      show(action, member);
    }
  };

  const renderCreateForm = (action, member) => <TeamMemberContainer cancelButtonTitle="Cancel" confirmButtonTitle={action} companyId={props.currentCompanyId} member={member} close={closeModal} updateInvalid={updateInvalid} />;

  const showHideMembers = () => {
    setModalState({
      ...modalState,
      showAllMembersLink: !modalState.showAllMembersLink,
    });
  };

  const handleSearchText = (event) => {
    let updatedList = teamMembersState.items;
    let getCurrentCompanyId = sessionState.companyId;
    updatedList = updatedList.filter(function (item) {
      let getUserRole = item.companies[getCurrentCompanyId].isAdmin ? "Admin" : "Member";
      let getUserStatus = item.companies[getCurrentCompanyId].status === "Pending" ? "Pending" : "Active";
      return item.firstName.toLowerCase().search(event.target.value.toLowerCase()) !== -1 || item.lastName.toLowerCase().search(event.target.value.toLowerCase()) !== -1 || item.email.toLowerCase().search(event.target.value.toLowerCase()) !== -1 || getUserRole.toLowerCase().search(event.target.value.toLowerCase()) !== -1 || getUserStatus.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
    });

    setModalState({ ...modalState, search: event.target.value, members: updatedList });
    dispatch(setGridItemsAction({ name: "team-members", items: updatedList }));
  };

  const { isUserAdmin } = props;
  const { action, showModal, hideFooter, modalTitle, modalBody, modalConfirmButtonTitle, modalOnConfirm, disableConfirmButton, showAllMembersLink, members } = modalState;

  return (
    <div className="team-members">
      {teamMembersState.pending && <LoadingComponent fullScreen />}
      <div className="members-header">
        <h3>Members</h3>
        {isUserAdmin && (
          <div className="link">
            <div className="add-members">
              <a onClick={() => show(ACTIONS.add)}>Add</a>
            </div>
          </div>
        )}
      </div>
      <div className="search-members">
        <Form
          onSubmit={() => true}
          render={(props) => (
            <div>
              <FieldGroupComponent name="search" type="search" value={modalState.search} formProps={{ placeholder: "Search" }} onChange={handleSearchText} />
            </div>
          )}
        />
      </div>

      {teamMembersState.items && teamMembersState.items.length > 0 && (
        <div>
          {showAllMembersLink && <GridContainer name="team-members" gridProps={GRID_PROPS} items={teamMembersState.items} size={teamMembersState.items.length} enablePagination itemsPerPage={4} sortBy={["firstName", "lastName"]} />}
          {!showAllMembersLink && <GridContainer name="team-members" gridProps={GRID_PROPS} items={teamMembersState.items} enablePagination itemsPerPage={teamMembersState.items.length} sortBy={["firstName", "lastName"]} />}
          {teamMembersState.items.length > 4 && (
            <div className={showAllMembersLink ? "displayLink" : "hideLink"}>
              <a onClick={() => showHideMembers()}>{showAllMembersLink ? "Show all " + teamMembersState.items.length + " Members" : "Hide Members"}</a>
            </div>
          )}
        </div>
      )}
      <ModalComponent dialogClassName={`team-members ${action}`} show={showModal} handleClose={closeModal} hideFooter={hideFooter} title={modalTitle} body={modalBody} cancelButtonTitle="Cancel" confirmButtonTitle={modalConfirmButtonTitle} onConfirm={modalOnConfirm} disableConfirmButton={disableConfirmButton} />
    </div>
  );
};

// TeamMembersComponent.propTypes = {
//   getMembers: PropTypes.func.isRequired,
//   removeMember: PropTypes.func.isRequired,
//   currentCompanyId: PropTypes.string,
//   submitMemberForm: PropTypes.func.isRequired,
//   isLoading: PropTypes.bool,
//   members: PropTypes.array.isRequired,
//   resendInvitation: PropTypes.func.isRequired,
//   user: PropTypes.object.isRequired,
//   isUserAdmin: PropTypes.bool,
// };

export default TeamMembersComponent;

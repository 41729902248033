import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";

import "./companyForm.scss";

import CompanyFormFieldsLayout from "../../../layouts/Company/CompanyForm/CompanyFormFieldsLayout";
import { Button } from "react-bootstrap";
import { prepareCompanyDataBeforeSave } from "../../../common";
import { useDispatch, useSelector } from "react-redux";
import { addCompanyAction, editCompanyAction } from "../../../redux/slice/companiesSlice";
import LoadingComponent from "../../core/LoadingComponent/LoadingComponent";

const CompanyFormComponent = (props) => {
  const [initValues, setInitValues] = useState(props.initialValues);
  const companiesState = useSelector((__state__) => __state__.companies);
  const dispatch = useDispatch();

  const { otherFields, initialValues, validationRules, confirmButtonTitle, close, action, cancelButtonTitle } = props;
  const handleSubmit = (company) => {
    setInitValues({
      ...company,
      size: company.companySize?.value,
    });
    prepareCompanyDataBeforeSave(company);
    if (action === "edit") dispatch(editCompanyAction({ company }));
    else dispatch(addCompanyAction({ company }));
  };

  if (companiesState.fulfilled) {
    close();
  }

  return (
    <>
      {companiesState.pending && <LoadingComponent fullscreen />}
      <Form
        onSubmit={handleSubmit}
        initialValues={initValues}
        validate={validationRules}
        render={(props) => {
          return (
            <form onSubmit={props.handleSubmit}>
              <CompanyFormFieldsLayout otherFields={otherFields} platform={initialValues.platform} />
              <div className="buttons">
                <Button className="btn btn-default dark" onClick={close}>
                  {cancelButtonTitle}
                </Button>
                <Button disabled={props.invalid || props.submitting} type="submit">
                  {confirmButtonTitle}
                </Button>
              </div>
            </form>
          );
        }}
      />
    </>
  );
};

CompanyFormComponent.propTypes = {
  otherFields: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  updateInvalid: PropTypes.func,
};

export default CompanyFormComponent;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { COMPANIES } from "../Constants";
import { createCompany, changeCompany, getCompany, getPendingFacilities, updatePendingFacilities } from "../../firebase/company";
import { removeCompanyId, setCompanyId } from "../../storage/localStorage";
import companyApi from "../../api/companyApi";
import { toast } from "react-toastify";
import { PLATFORMS } from "../../constants/common";
import { getAuthInfoAction } from "./sessionSlice";

const prepareCompany = ({ id, name, platform, phone, size, companySize, industry, vertical, country, address, city, state, postalCode, otherIndustry, otherVertical }) => ({
  ...(id && { id }),
  name,
  enrollmentType: platform.map((type) => PLATFORMS[type.trim()]).join(","),
  phoneNo: phone,
  companySize: size || companySize,
  industry,
  vertical: industry === "Fitness" ? vertical : null,
  country,
  address,
  city,
  state,
  postalCode,
  otherIndustry,
  otherVertical,
});

// INITIAL STATE
const initialState = COMPANIES.INITIAL_STATE;

const doGetCompanies = async (__companies__) => {
  const companyIds = __companies__ && Object.keys(__companies__);
  const companies = await Promise.all(companyIds.map((c) => getCompany(c)));
  const companyId = companies ? companies[Object.keys(companies)[0]].companyId : "";

  return { companies, companyId };
};

// CREATE ACTIONS
export const getCompaniesAction = createAsyncThunk(COMPANIES.ACTION.GET_COMPANIES, async (payload, { rejectWithValue, getState, dispatch }) => {
  const { companies } = payload;
  const companyIds = companies && Object.keys(companies);

  try {
    const companies = await Promise.all(companyIds.map((c) => getCompany(c)));
    const companyId = companies ? companies[Object.keys(companies)[0]].companyId : "";

    return { companies, companyId };
  } catch (error) {
    console.log("***** getCompaniesAction error: ", error);
    return rejectWithValue(error);
  }
});

export const removeCurrentCompanyAction = createAsyncThunk(COMPANIES.ACTION.REMOVE_CURRENT_COMPANY, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    await removeCompanyId();
    return;
  } catch (error) {
    console.log("***** removeCurrentCompanyAction error: ", error);
    return rejectWithValue(error);
  }
});

export const addCompanyAction = createAsyncThunk(COMPANIES.ACTION.ADD_COMPANY, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const { company } = payload;
    const response = await createCompany(company);
    const result = await response.json();
    if (response.ok) {
      // update user.companies here START
      dispatch(getAuthInfoAction());
      // update user.companies here END
      toast(COMPANIES.RESPONSE.MESSAGE.SUCCESSFUL_CREATE_COMPANY);
      return false;
    } else if (response.status !== COMPANIES.RESPONSE.STATUS_CODE.INTERNAL_SERVER_ERROR_CODE) {
      throw result;
    }
  } catch (error) {
    console.log("***** addCompanyAction error: ", JSON.stringify(error));
    return rejectWithValue(error);
  }
});

export const editCompanyAction = createAsyncThunk(COMPANIES.ACTION.EDIT_COMPANY, async (payload, { rejectWithValue, getState, dispatch }) => {
  try {
    const { company } = payload;
    company.platform = typeof company.platform === "string" ? company.platform.split(",") : company.platform;
    if (company.id) {
      await companyApi.update(prepareCompany(company));
    }

    const response = await changeCompany(company);
    const result = await response.json();
    if (response.ok) {
      dispatch(getAuthInfoAction());
      toast(COMPANIES.RESPONSE.MESSAGE.SUCCESSFUL_UPDATE_COMPANY);

      // Check if the company has license with pending facilities and update if company name changed
      let pendingApprovalObj = await getPendingFacilities(company.firebaseId);
      if (pendingApprovalObj && pendingApprovalObj.companyName !== company.name.trim()) {
        pendingApprovalObj.companyName = company.name.trim();
        await updatePendingFacilities(company.firebaseId, pendingApprovalObj);
      }
      return false;
    } else if (response.status !== COMPANIES.RESPONSE.STATUS_CODE.INTERNAL_SERVER_ERROR_CODE) {
      throw result;
    }
  } catch (error) {
    console.log("***** editCompanyAction error: ", error);
    return rejectWithValue(error);
  }
});

export const initCompaniesAction = createAsyncThunk(COMPANIES.ACTION.INIT_COMPANIES, async (payload, { rejectWithValue, getState, dispatch }) => {
  return;
});

// CREATE SLICE
const companiesSlice = createSlice({
  name: COMPANIES.SLICE,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(initCompaniesAction.pending, (state) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(initCompaniesAction.fulfilled, (state) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(initCompaniesAction.rejected, (state) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(getCompaniesAction.pending, (state, action) => {
      state.pending = true;
      state.items = [];
      state.companyId = null;
      state.currentCompany = {};
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(getCompaniesAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.items = action.payload.companies;
      state.companyId = action.payload.companyId;
      state.currentCompany = action.payload.companies.find((c) => c.firebaseId === action.payload.companyId);
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(getCompaniesAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.items = [];
      state.companyId = null;
      state.currentCompany = {};
      state.rejected = true;
      state.error = action.payload;
    });

    builder.addCase(removeCurrentCompanyAction.pending, (state, action) => {
      state.pending = true;
      state.items = [];
      state.companyId = null;
      state.currentCompany = {};
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(removeCurrentCompanyAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(removeCurrentCompanyAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
    });

    builder.addCase(addCompanyAction.pending, (state, action) => {
      state.pending = true;
      state.fulfilled = false;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(addCompanyAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(addCompanyAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
    });

    builder.addCase(editCompanyAction.pending, (state, action) => {
      state.pending = true;
      state.items = [];
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(editCompanyAction.fulfilled, (state, action) => {
      state.pending = false;
      state.fulfilled = true;
      state.rejected = false;
      state.error = null;
    });

    builder.addCase(editCompanyAction.rejected, (state, action) => {
      state.pending = false;
      state.fulfilled = false;
      state.rejected = true;
      state.error = action.payload;
    });
  },
});

// CREATE REDUCER
const companiesReducer = companiesSlice.reducer;
export default companiesReducer;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NavDropdown } from "react-bootstrap";
import ReactGA from "react-ga";

import moment from "moment";

import LicensePanelGroupComponent from "../../../components/profile/Licenses/LicensePanelGroupComponent";
import ModalComponent from "../../core/ModalComponent/ModalComponent";
import LoadingComponent from "../../core/LoadingComponent/LoadingComponent";
import TruncateComponent from "../../core/TruncateComponent/TruncateComponent";
import DropDownComponent from "../../core/DropDownComponent/DropDownComponent";
import LicenseOuterFormContainer from "../../../containers/LicenseOuterFormContainer";
import LicensePlatformIconComponent from "./LicensePlatformIconComponent";
import { LICENSES_MENU } from "../../core/MenuComponent/MenuConstants";
import { ACTIONS, PLATFORM_NAMES } from "../../../constants/common";

import "./licenses.scss";
import { useDispatch, useSelector } from "react-redux";
import { addLicenseAction, changeLicenseAction, deleteLicenseAction, getLicensesAction, initLicensesAction, moveLicenseAction } from "../../../redux/slice/licensesSlice";

const errorMessage = {
  318: "This license cannot be deleted because it is in production. Please contact Support.",
  319: "This license cannot be deleted because users exist for this license.",
  317: "Unknown error. Please try again at a later time.",
};

const LICENSES_ACTION = {
  ...LICENSES_MENU,
};

const initialValues = {};
let isCreateAction = true;

const __InitState__ = (props) => {
  return {
    show: false,
    modalTitle: "",
    modalBody: "",
    modalConfirmButtonTitle: "Yes",
    modalCancelButtonTitle: "No",
    activeKey: props.activePanel,
    modalOnConfirm: () => {},
    error: "",
    modalPage: 1,
    showTooltip: {},
    subEnabledHere: false,
  };
};

const LicensesComponent = (props) => {
  const dispatch = useDispatch();
  const licensesState = useSelector((__state__) => __state__.licenses);
  const sessionState = useSelector((__state__) => __state__.session);
  const [modalState, setModalState] = useState(__InitState__(props));
  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.set({ title: "Licenses Page" });
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    if (licensesState.rejected) {
      console.log(licensesState.error);
    } else if (licensesState.fulfilled === true && modalState.show === true) {
      closeModal(true);
    }
  }, [licensesState.fulfilled, licensesState.rejected]);

  const canEdit = sessionState.user.companies[sessionState.companyId].isAdmin;

  const GRID_PROPS = [
    {
      className: "name",
      getter: (item) => (
        <div>
          <LicensePlatformIconComponent item={item} handleView={handleView} />
          <div className="placeholder">
            <TruncateComponent lines={1} className="main" text={item.name} />
            <TruncateComponent lines={2} className="description" text={item.description} />
          </div>
        </div>
      ),
    },
    {
      id: "platform-category",
      title: "Platform",
      className: "platform",
      getter: (item) => (
        <div>
          {item.category}
          <p>{item.platform}</p>
        </div>
      ),
    },
    {
      className: "license-date",
      getter: (item) => {
        const lastUpdated = moment(item.lastUpdated);

        return (
          <div>
            <div>{item.license}</div>
            <span className="license-updated-date">
              Updated: {lastUpdated.format("MMM DD, YYYY")} at {lastUpdated.format("hh:mm A")}
            </span>
          </div>
        );
      },
    },
    {
      title: "Ellipsis",
      className: "ellipsis",
      getter: (item) => {
        let menuItems = [];
        Object.keys(LICENSES_ACTION).forEach((key) => {
          if (item.env === "dev" && LICENSES_ACTION[key].forDev) {
            if ((key === "move-production" || key === "delete-license") && canEdit && !item.inProd) {
              menuItems.push({
                title: LICENSES_ACTION[key].title,
                action: LICENSES_ACTION[key].action,
              });
            } else if (key !== "move-production" && key !== "delete-license") {
              if ((key === "edit-license" && !item.inProd) || (key === "edit-dev-license" && item.inProd)) {
                menuItems.push({
                  title: LICENSES_ACTION[key].title,
                  action: LICENSES_ACTION[key].action,
                });
              }
            }
          } else if (item.env === "prod" && !LICENSES_ACTION[key].forDev) {
            menuItems.push({
              title: LICENSES_ACTION[key].title,
              action: LICENSES_ACTION[key].action,
            });
          }
        });

        return (
          <DropDownComponent className="license-dropdown" title={<span className="ellipsis-span" />} id="members-menu">
            {menuItems.map(({ title, action }, index) => (
              <NavDropdown.Item className={action} key={index} eventKey={title} onClick={() => licenseAction(action, item)}>
                {title}
              </NavDropdown.Item>
            ))}
          </DropDownComponent>
        );
      },
    },
  ];

  const showModal = (action, licenseData) => {
    let __state__ = {};

    ReactGA.event({
      category: "Licenses Component",
      action: "Clicked Add Icon",
      label: "Icon",
    });

    if (action === ACTIONS.move) {
      isCreateAction = false;
      let data = { ...licenseData };
      if (data.platform === PLATFORM_NAMES.web) {
        delete data.facilitiesAdded;
        delete data.facilitiesSubscribedFor;
        delete data.facilitiesSubscriptionPending;
        delete data.facilitiesSubscriptionRejected;
      }

      __state__ = {
        action: ACTIONS.move,
        modalTitle: "Move License to production",
        modalBody: renderForm(data, action, data && data.platform === PLATFORM_NAMES.web ? 2 : 1, true, "Cancel", "Move", "Move License to Production"),
        modalConfirmButtonTitle: "Move",
        modalCancelButtonTitle: "Cancel",
        modalOnConfirm: props.moveLicenseForm,
        pageAmount: data && data.platform === PLATFORM_NAMES.web ? 2 : 1,
      };
    } else if (action === ACTIONS.add) {
      isCreateAction = true;
      __state__ = {
        action: ACTIONS.add,
        modalTitle: "Add License",
        modalBody: renderForm(licenseData, action, licenseData && licenseData.platform === PLATFORM_NAMES.web ? 2 : 1, true, "Cancel", "Create", "Add License"),
        modalConfirmButtonTitle: "Create",
        modalCancelButtonTitle: "Cancel",
        modalOnConfirm: props.submitLicenseForm,
        pageAmount: licenseData && licenseData.platform === PLATFORM_NAMES.web ? 2 : 1,
      };
    } else if (action === ACTIONS.editDev) {
      const { devInProd } = props.licenses;

      isCreateAction = false;
      if (licenseData.env === "prod") {
        for (var license in devInProd) {
          if (devInProd[license].firebaseId === licenseData.firebaseId) {
            licenseData = devInProd[license];
            break;
          }
        }
      }
      __state__ = {
        action: ACTIONS.editDev,
        modalTitle: "Edit License [Development]",
        modalBody: renderForm(licenseData, action, licenseData && licenseData.platform === PLATFORM_NAMES.web ? 2 : 1, true, "Cancel", "Update", "Edit License [Development]"),
        modalConfirmButtonTitle: "Update",
        modalCancelButtonTitle: "Cancel",
        modalOnConfirm: props.editDevLicenseForm,
        pageAmount: licenseData && licenseData.platform === PLATFORM_NAMES.web ? 2 : 1,
      };
    } else if (action === ACTIONS.editProd) {
      isCreateAction = false;
      __state__ = {
        action: ACTIONS.editProd,
        modalTitle: "Edit License [Production]",
        modalBody: renderForm(licenseData, action, licenseData && licenseData.platform === PLATFORM_NAMES.web ? 2 : 1, true, "Cancel", "Update", "Edit License [Production]"),
        modalConfirmButtonTitle: "Update",
        modalCancelButtonTitle: "Cancel",
        modalOnConfirm: props.editProdLicenseForm,
        pageAmount: licenseData && licenseData.platform === PLATFORM_NAMES.web ? 2 : 1,
      };
    } else if (action === ACTIONS.delete) {
      isCreateAction = false;
      __state__ = {
        action: ACTIONS.delete,
        modalTitle: "Delete License",
        modalBody: renderForm(licenseData, action, 1, false, "Cancel", "Delete", "Delete License"),
        modalConfirmButtonTitle: "Delete",
        modalCancelButtonTitle: "Cancel",
        pageAmount: 1,
        modalOnConfirm: () => {
          let promise;
          const apigeeAppId = licenseData.apigeeAppId ? licenseData.apigeeAppId : 0;
          dispatch(
            deleteLicenseAction({
              company: sessionState.company,
              licenseId: licenseData.firebaseId,
              licenseName: licenseData.name,
              user: sessionState.user,
              apigeeAppId,
            })
          );
        },
      };
    }
    setModalState((prevState) => ({
      ...prevState,
      ...__state__,
      show: true,
      error: "",
      licenseData: licenseData,
      callbacks: null,
      additional: null,
      action,
    }));
  };

  const closeModal = (__reloadLicenses__ = false) => {
    ReactGA.event({
      category: "Licenses Component",
      action: "Clicked Close Modal Icon",
      label: "Icon",
    });
    if (__reloadLicenses__ === true) dispatch(getLicensesAction({ companyId: sessionState.companyId }));
    else dispatch(initLicensesAction());
    setModalState({
      ...modalState,
      show: false,
      modalPage: 1,
      modalInProgress: false,
      modal2InProgress: false,
      incorrectIds: "",
      callbacks: null,
      additional: null,
    });
  };

  const licenseAction = (action, licenseData) => {
    if (action === "move-license") {
      ReactGA.event({
        category: "Licenses Component",
        action: "Clicked Move License to Production",
        label: "Dropdown",
      });
      showModal("move", licenseData);
    } else if (action === "delete-license") {
      ReactGA.event({
        category: "Licenses Component",
        action: "Clicked Delete License",
        label: "Dropdown",
      });
      showModal("delete", licenseData);
    } else if (action === "edit-prod-license") {
      ReactGA.event({
        category: "Licenses Component",
        action: "Clicked Edit Production License",
        label: "Dropdown",
      });
      showModal("editProd", licenseData);
    } else {
      ReactGA.event({
        category: "Licenses Component",
        action: "Clicked Edit Dev License",
        label: "Dropdown",
      });
      showModal("editDev", licenseData);
    }
  };

  const handleSubmit = (action, data) => {
    console.log(action);
    console.log(data);
    switch (action) {
      case ACTIONS.add:
        console.log("dispatch addLicenseAction() data: ", data);
        dispatch(addLicenseAction({ user: sessionState.user, company: sessionState.company, licenseData: { ...data, category: data.category?.value, platform: data.platform?.value } }));
        break;
      case ACTIONS.delete:
        console.log("dispatch deleteLicenseAction()");
        break;
      case ACTIONS.editDev:
      case ACTIONS.editProd:
        console.log("dispatch changeLicenseAction()");
        dispatch(changeLicenseAction({ user: sessionState.user, company: sessionState.company, licenseData: { ...data, category: data.category?.value } }));
        break;
      case ACTIONS.move:
        console.log("dispatch moveLicenseAction()");
        dispatch(moveLicenseAction({ user: sessionState.user, company: sessionState.company, licenseData: { ...data, category: data.category?.value } }));
        break;
      default:
        break;
    }
  };

  const renderForm = (licenseData, action, pageAmount, isNewModal, modalCancelButtonTitle, modalConfirmButtonTitle, modalTitle, modalPage = 1) => {
    const { platforms } = props;
    const toolTipFlag = action === ACTIONS.move;
    if (action === ACTIONS.add) {
      return <LicenseOuterFormContainer handleSubmit={handleSubmit} modalTitle={modalTitle} cancelButtonTitle={modalCancelButtonTitle} confirmButtonTitle={modalConfirmButtonTitle} initialValues={modalPage === 2 ? licenseData : initialValues} isCreateAction={isCreateAction} platforms={platforms} close={closeModal} action={action} pageAmount={pageAmount} page={modalPage} nextPage={nextPage} prevPage={prevPage} inProgress={modalState.modalInProgress} inProgress2={modalState.modal2InProgress} changePlatform={changePlatform} addIncorrectIds={addIncorrectIds} incorrectIds={modalState.incorrectIds} updateInvalid={updateInvalid} savedCallbacks={!isNewModal ? modalState.callbacks : null} saveCallbacks={saveCallbacks} savedAdditional={!isNewModal ? modalState.additional : null} saveAdditional={saveAdditional} updateSubEnabled={updateSubEnabled} />;
    } else if (action === ACTIONS.delete) {
      return (
        <div>
          <div>
            Do you wish to delete the development license for <b>{sessionState.company.name}</b>? This cannot be undone.
          </div>
          <br />
        </div>
      );
    } else {
      return <LicenseOuterFormContainer handleSubmit={handleSubmit} modalTitle={modalTitle} cancelButtonTitle={modalCancelButtonTitle} confirmButtonTitle={modalConfirmButtonTitle} isCreateAction={isCreateAction} initialValues={licenseData} close={closeModal} platforms={platforms} action={action} toolTipFlag={toolTipFlag} pageAmount={pageAmount} page={modalPage} nextPage={nextPage} prevPage={prevPage} inProgress={modalState.modalInProgress} inProgress2={modalState.modal2InProgress} addIncorrectIds={addIncorrectIds} incorrectIds={modalState.incorrectIds} updateInvalid={updateInvalid} savedCallbacks={!isNewModal ? modalState.callbacks : null} saveCallbacks={saveCallbacks} savedAdditional={!isNewModal ? modalState.additional : null} saveAdditional={saveAdditional} updateSubEnabled={updateSubEnabled} />;
    }
  };

  const handleView = (action, item) => {
    const actionMap = {
      "move-license": { action: "move", confirmButtonTitle: "Move", modalTitle: "Move License" },
      "delete-license": { action: "delete", confirmButtonTitle: "Delete", modalTitle: "Delete License" },
      "edit-prod-license": { action: "editProd", confirmButtonTitle: "Update", modalTitle: "Edit License [Production]" },
      "edit-dev-license": { action: "editDev", confirmButtonTitle: "Update", modalTitle: "Edit License [Development]" },
    };

    let temp = modalState.showTooltip;
    temp[item.id] = false;
    setModalState(
      (prevState) => ({
        ...prevState,
        action: actionMap[action].action,
        show: true,
        modalPage: 2,
        showTooltip: temp,
        licenseData: item,
        pageAmount: item && item.platform === PLATFORM_NAMES.web ? 2 : 1,
        modalInProgress: true,
        error: "",
        callbacks: null,
        additional: null,
        modalTitle: actionMap[action].modalTitle,
        modalBody: renderForm(item, action, item && item.platform === PLATFORM_NAMES.web ? 2 : 1, true, "Cancel", actionMap[action].confirmButtonTitle, actionMap[action].modalTitle, 2),
      })
      // () => {
      //   licenseAction(action, item);
      // }
    );
  };

  const nextPage = (licenseData, action, cancelButtonTitle, confirmButtonTitle, modalPage, modalTitle) => {
    setModalState((prevState) => ({
      ...prevState,
      modalPage,
      modalInProgress: true,
      show: true,
      pageAmount: licenseData && licenseData.platform === PLATFORM_NAMES.web ? 2 : 1,
      error: "",
      licenseData: licenseData,
      callbacks: null,
      additional: null,
      action,
      modalTitle,
      modalBody: renderForm(licenseData, action, licenseData && licenseData.platform === PLATFORM_NAMES.web ? 2 : 1, true, cancelButtonTitle, confirmButtonTitle, modalTitle, modalPage),
    }));
  };

  const prevPage = (licenseData, action, cancelButtonTitle, confirmButtonTitle, modalPage, modalTitle) => {
    setModalState((prevState) => ({
      ...prevState,
      modalPage,
      modal2InProgress: true,
      show: true,
      pageAmount: licenseData && licenseData.platform === PLATFORM_NAMES.web ? 2 : 1,
      error: "",
      licenseData: licenseData,
      callbacks: null,
      additional: null,
      action,
      modalTitle,
      modalBody: renderForm(licenseData, action, licenseData && licenseData.platform === PLATFORM_NAMES.web ? 2 : 1, true, cancelButtonTitle, confirmButtonTitle, modalTitle, modalPage),
    }));

    // setModalState(
    //   (prevState) => ({
    //     ...prevState,
    //     modalPage: modalState.modalPage - 1,
    //     modal2InProgress: true,
    //   })
    // () => {
    //   setState({
    //     modalBody: renderForm(state.licenseData, state.action, state.pageAmount),
    //   });
    // }
    // );
  };

  const changePlatform = (selectedPlatform, licenseData, action, cancelButtonTitle, confirmButtonTitle, modalPage, modalTitle) => {
    const __pageAmount__ = selectedPlatform?.value === PLATFORM_NAMES.web ? 2 : 1;
    setModalState((prevState) => ({
      ...prevState,
      modalPage,
      modalInProgress: true,
      show: true,
      pageAmount: __pageAmount__,
      error: "",
      licenseData: licenseData,
      callbacks: null,
      additional: null,
      action,
      modalTitle,
      modalBody: renderForm(licenseData, action, __pageAmount__, true, cancelButtonTitle, confirmButtonTitle, modalTitle, modalPage),
    }));
  };

  const addIncorrectIds = (ids) => {
    setModalState(
      (prevState) => ({
        ...prevState,
        incorrectIds: ids,
      })
      // () => {
      //   setState({
      //     modalBody: renderForm(state.licenseData, state.action, state.pageAmount),
      //   });
      // }
    );
  };

  const updateInvalid = (invalid) => {
    setModalState((prevState) => ({
      ...prevState,
      formInvalid: invalid,
    }));
  };

  const updateSubEnabled = (subEnabled) => {
    setModalState((prevState) => ({
      ...prevState,
      subEnabledHere: subEnabled,
    }));
  };

  const saveCallbacks = (callbacks) => {
    setModalState((prevState) => ({
      ...prevState,
      callbacks: callbacks,
    }));
  };

  const saveAdditional = (additional) => {
    setModalState((prevState) => ({
      ...prevState,
      additional: additional,
    }));
  };

  const getInvalidCallback = () => {
    const { callbacks, modalPage, subEnabledHere } = modalState;
    let callbackNumber = 0;
    callbacks &&
      Object.keys(callbacks).forEach((callback) => {
        if (callbacks[callback] && callbacks[callback].length > 0) {
          callbackNumber++;
        }
      });
    return subEnabledHere && callbackNumber <= 0 && modalPage === 2;
  };

  const getInvalidAdditional = () => {
    const { additional, modalPage, subEnabledHere } = modalState;
    let additionalNumber = 0;
    additional &&
      Object.keys(additional).forEach((add) => {
        if (additional[add] && additional[add]) {
          additionalNumber++;
        }
      });
    return subEnabledHere && additionalNumber <= 0 && modalPage === 2;
  };

  const { areSelectedItemsEmpty, isLoading, isDev, backToDevelopment } = props;
  let devLicenses = props.licenses.dev;
  let devInProdLicenses = props.licenses.devInProd;
  let prodLicenses = props.licenses.prod;
  const { activeKey, action, show, modalTitle, modalOnConfirm, modalPage, pageAmount, incorrectIds, formInvalid, submitting } = modalState;
  let { modalBody } = modalState;

  let fullDevLicenses = [...devLicenses, ...devInProdLicenses];

  if (modalState.error !== "") {
    modalBody = (
      <div>
        {modalBody}
        <div style={{ color: "red" }}>{modalState.error}</div>
      </div>
    );
  } else {
    modalBody = <div>{modalBody}</div>;
  }

  return (
    <div>
      {licensesState.pending && <LoadingComponent fullScreen />}
      <div className="licenses-component">
        <h3>Licenses</h3>
        <span className="link">
          <a onClick={() => showModal(ACTIONS.add)}>
            <span className="add-fonticon" /> Add
          </a>
        </span>
        {isDev && (
          <span className="prod-key">
            <span className="prod-icon">P</span>License added to Production
          </span>
        )}
        <div>
          <LicensePanelGroupComponent activeKey={activeKey} canEdit={canEdit} gridProps={GRID_PROPS} devLicenses={fullDevLicenses} prodLicenses={prodLicenses} areSelectedItemsEmpty={areSelectedItemsEmpty} isDev={isDev} addLicenseMethod={showModal} backToDevelopment={backToDevelopment} />
        </div>
      </div>
      <ModalComponent
        hideFooter={action !== ACTIONS.delete}
        dialogClassName={`licenses-modal ${action}`}
        show={show}
        handleClose={closeModal}
        title={modalTitle}
        body={modalBody}
        cancelButtonTitle={modalState.modalCancelButtonTitle}
        confirmButtonTitle={modalState.modalConfirmButtonTitle}
        onConfirm={modalOnConfirm}
        pages={pageAmount}
        page={modalPage}
        noCloseButton
        submitting={submitting}
        disableConfirmButton={formInvalid || (getInvalidCallback() && getInvalidAdditional()) || (!!incorrectIds && incorrectIds.length > 0 && modalPage === 2)}
        setSubmitting={(val) => {
          setModalState((prevState) => ({ ...prevState, submitting: val }));
        }}
      />
    </div>
  );
};

// LicensesComponent.propTypes = {
//   isLoading: PropTypes.bool.isRequired,
//   licenses: PropTypes.object.isRequired,
//   areSelectedItemsEmpty: PropTypes.bool.isRequired,
//   canEdit: PropTypes.bool.isRequired,
//   activePanel: PropTypes.string,
//   isDev: PropTypes.bool.isRequired,
//   platforms: PropTypes.array.isRequired,
//   submitLicenseForm: PropTypes.func.isRequired,
//   moveLicenseForm: PropTypes.func.isRequired,
//   editDevLicenseForm: PropTypes.func.isRequired,
//   editProdLicenseForm: PropTypes.func.isRequired,
//   deleteLicense: PropTypes.func.isRequired,
//   backToDevelopment: PropTypes.func.isRequired,
//   company: PropTypes.object.isRequired,
//   user: PropTypes.object.isRequired,
// };

export default LicensesComponent;
